import { getSerieBySerieHistoryIdApi } from 'app/api/seriesApi';
import { GET_SERIE_BY_SERIE_HISTORY_ID_FAILURE, GET_SERIE_BY_SERIE_HISTORY_ID_REQUEST, GET_SERIE_BY_SERIE_ID_SUCCESS } from '../Question/Serie/SerieGet/serieGet.actions';
import { EXPIRED_SESSION, RETRIEVE_SERIE_ERROR, RETRIEVE_SERIE_ERROR_404 } from 'app/Snackbar/NotificationMessages';
import { notificationError } from '../Notification/notifications.actions';

export const ADD_ROOM = 'ADD_ROOM';
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
export const REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const SET_QUIZ_STARTED = 'SET_QUIZ_STARTED';
export const RESET_ROOMS = 'RESET_ROOMS';
export const UPDATE_ROOM = 'UPDATE_ROOM';

export const addRoom = (roomInfos) => ({
    type: ADD_ROOM,
    roomInfos
});

export const addParticipant = (participantInfos) => ({
    type: ADD_PARTICIPANT,
    participantInfos
});

export const removeParticipant = (participantInfos) => ({
    type: REMOVE_PARTICIPANT,
    participantInfos
});

export const setCurrentQuestionId = (currentQuestionInfos) => ({
    type: SET_CURRENT_QUESTION,
    currentQuestionInfos
});

export const setQuizStarted = (currentQuestionInfos) => ({
    type: SET_QUIZ_STARTED,
    currentQuestionInfos
});

export const resetRooms = () => ({
    type: RESET_ROOMS
});

export const updateRoom = (roomId, updatedRoomInfo) => ({
    type: UPDATE_ROOM,
    roomId,
    updatedRoomInfo
});

export const getSerieBySerieHistoryIdForNewLeader = (serieHistoryId, userId) => (dispatch) => {
    dispatch({ type: GET_SERIE_BY_SERIE_HISTORY_ID_REQUEST });

    return getSerieBySerieHistoryIdApi(serieHistoryId, userId).then((response) => {
        const payload = {
            maxDuration: response.data.durationLeft,
            questionAskedList: response.data.questionsNotDone.map(question => question.question)
        };
        dispatch({
            type: GET_SERIE_BY_SERIE_ID_SUCCESS,
            payload
        });
    }, (error) => {
        dispatch({
            type: GET_SERIE_BY_SERIE_HISTORY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR_404));
        } else {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR));
        }
    });
};