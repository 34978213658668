import React, { useState } from 'react';
import {
    Button, Dialog, DialogTitle,
    FormControlLabel, Switch,
    Tooltip, Typography,
    useTheme, withStyles
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import {
    displaySerieTitleTooltips,
    displaySerieDescriptionTooltips,
    displayTimerTooltips,
    displaySerieQuestionListTooltips,
    serieIsEqualTo,
    formIsValid
} from '../../CreateEditSerie.functions';
import PropTypes from 'prop-types';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { addSerie, editSerie } from 'app/redux/actions/Question/Serie/SerieSet/serieSet.actions';
import { connect, useDispatch } from 'react-redux';
import { mapLocalTimeToDuration } from 'app/utils/date.functions';
import { useHistory } from 'react-router-dom';
import { setCategoryFilter, setFamilyFilter, setSearchFilter } from 'app/redux/actions/Filter/filter.actions';
import { setExcilyensSearchFilter, setExcilyensSkillsFilter, setPromoFilter } from 'app/redux/actions/Excilyen/ExcilyenFilter/excilyenFilters.actions';
import { QUESTIONS_PAGE_URL } from 'app/Routes';
import { SerieTypeEnum } from 'app/utils/SerieTypeEnum';

export function CreateEditSerieFormFooter(props) {

    const theme = useTheme();
    const dispatch = useDispatch();

    const history = useHistory();

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [randomSelected, setRandomSelected] = useState(props.serie.type === SerieTypeEnum.ALEATOIRE);

    const emptySerie = () => ({
        id: null,
        title: '',
        description: '',
        maxDuration: '',
        creator: {
            id: getCurrentUserId()
        },
        questionReducedList: []
    });

    const HtmlTooltip = withStyles(() => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9'
        }
    }))(Tooltip);

    const timerValidation = (timerValue) => {
        if(timerValue && timerValue !== '' && timerValue !== '00:00') {
            return mapLocalTimeToDuration(timerValue);
        }
        return null;
    };

    const removeTableData = (completedSerie) => {
        const copy = JSON.parse(JSON.stringify(completedSerie));
        if(copy) {
            copy.questionReducedList.forEach((elem) => {
                delete elem.tableData;
            });
        }
        return copy;
    };

    const dispatchSerie = () => {
        const serieUpdated = { ...props.serie };
        if(randomSelected) {
            serieUpdated.type = SerieTypeEnum.ALEATOIRE;
        } else {
            serieUpdated.type = SerieTypeEnum.STATIQUE;
        }
        serieUpdated.maxDuration = timerValidation(serieUpdated.maxDuration);

        serieUpdated.questionReducedList = serieUpdated.questionReducedList.map((question) => ({
            id: question.id
        }));
        if(props.isEditing) {
            dispatch(editSerie(removeTableData(serieUpdated)));
        } else {
            dispatch(addSerie(removeTableData(serieUpdated)));
        }
    };

    function submitSerie() {
        if(!props.isEditing && props.serie && props.serie.creator.id !== getCurrentUserId) {
            props.changeCreator();
        }
        if(formIsValid(props.serie)) {
            dispatchSerie();
        }
        history.push(QUESTIONS_PAGE_URL);
    }

    const handleCloseCancel = () => {
        setDialogOpen(false);
    };

    const handleConfirmCancel = () => {
        props.setSerie(emptySerie());
        setDialogOpen(false);
        history.push(QUESTIONS_PAGE_URL);
    };

    const handleClickOpenCancel = () => {
        if(serieIsEqualTo(props.serie, emptySerie())) {
            handleConfirmCancel();
        } else {
            setDialogOpen(true);
        }
    };

    const handleSwitch = () => {
        setRandomSelected(!randomSelected);
    };

    return (
        <div className="createEditSerieFormHeader">
            <div className="containerSelected">
                <div className="buttonContainer">
                    <HtmlTooltip
                        className="addButtonTooltip"
                        arrow
                        title={
                            formIsValid(props.serie)
                                ? ''
                                : <>
                                    <Typography>{displaySerieTitleTooltips(props.serie.title)}</Typography>
                                    <Typography>{displaySerieDescriptionTooltips(props.serie.description)}</Typography>
                                    <Typography>{displayTimerTooltips(props.serie.maxDuration)}</Typography>
                                    <Typography>{displaySerieQuestionListTooltips(props.serie)}</Typography>
                                </>
                        }
                    >
                        <span>
                            <Button
                                data-testid="submit-button"
                                className="addEditButton"
                                color="primary"
                                disabled={!formIsValid(props.serie)}
                                startIcon={<DoneIcon />}
                                onClick={() => submitSerie(props.serie)}
                            >
                                {props.isEditing ? 'Éditer' : 'Ajouter'}
                            </Button>
                        </span>
                    </HtmlTooltip>
                    <Button
                        data-testid="cancel-button"
                        className="cancelButton"
                        color="primary"
                        onClick={() => handleClickOpenCancel()}
                        startIcon={<CancelIcon />}
                    >
                        Annuler
                    </Button>
                </div>
                <FormControlLabel
                    className="switchSelected"
                    control={
                        <Switch
                            data-testid="switch-random"
                            checked={randomSelected}
                            onChange={handleSwitch}
                            color="primary"
                        />
                    }
                    label="Lancement aléatoire"
                />
            </div>
            <ValidateDialog
                className="validateDialog"
                open={dialogOpen}
                onClose={() => handleCloseCancel()}
                onConfirm={() => handleConfirmCancel()}
                resetSearchAndFilters={props.resetSearchAndFilters}
            />
        </div>
    );

}

CreateEditSerieFormFooter.propTypes = {
    serie: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    changeCreator: PropTypes.func.isRequired,
    setSerie: PropTypes.func.isRequired,
    resetSearchAndFilters: PropTypes.func
};

function ValidateDialog(props) {
    const { onClose, open, onConfirm } = props;

    const handleClose = () => {
        onClose();
    };

    const handleConfirm = () => {
        onConfirm();
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <div className="cancelButtonContainer">
                <DialogTitle id="simple-dialog-title" data-testid="simple-dialog-title">
                    Voulez-vous annuler vos changements ?
                </DialogTitle>
                <Button
                    data-testid="close-dialog-button"
                    className="closeDialogButton"
                    onClick={() => handleClose()}
                >
                    non
                </Button>
                <Button
                    data-testid="confirm-cancel-button"
                    className="confirmCancelButton"
                    onClick={() => handleConfirm()}
                >
                    oui
                </Button>
            </div>
        </Dialog>
    );
}

ValidateDialog.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    serie: PropTypes.object,
    resetSearchAndFilters: PropTypes.func
};

function mapDispatchToProps(dispatch) {
    return {
        resetSearchAndFilters: () => {
            dispatch(setSearchFilter(''));
            dispatch(setCategoryFilter([]));
            dispatch(setFamilyFilter([]));
            dispatch(setPromoFilter([]));
            dispatch(setExcilyensSearchFilter(''));
            dispatch(setExcilyensSkillsFilter([]));
        }
    };
}

export default connect(mapDispatchToProps)(CreateEditSerieFormFooter);
