/* eslint-disable complexity */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SockJsClient from 'react-stomp';
import { Toolbar, useMediaQuery, AppBar, IconButton, Grid } from '@material-ui/core';
import { FilterList, Search } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import './Header.scss';
import SearchContainer from '../containers/SearchContainer';
import Title from '../components/Title';
import PropTypes from 'prop-types';
import FilterSearchMenu from '../components/FilterSearchMenu';
import MenuSmallScreen from '../components/MenuSmallScreen';
import { HeaderParams } from '../../utils/HeaderEnum';
import { setDisplayLeftDrawer } from '../../redux/actions/Display/display.actions';
import ClocheNotification from '../components/ClocheNotification';
import { getCurrentUserById } from '../../redux/actions/User/user.actions';
import { closeDrawer, openDrawer } from 'app/redux/actions/Drawer/drawer.actions';
import Tasks from '../components/Tasks';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useResizeDetector } from 'react-resize-detector';
import NavbarLargeScreen from '../components/NavbarLargeScreen';
import MenuLargeScreen from '../components/MenuLargeScreen';
import DialogQuizInteractifMenuSmallScreen from '../components/DialogQuizInteractifSmallScreen';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { addRoom } from 'app/redux/actions/InteractiveQuiz/interactiveQuizRoom.actions';
import { skillActions } from 'app/redux/slices/skills.slice';
import { getRoomExistsApi } from 'app/api/interactiveQuizApi';
import { WebSocketActionsEnum } from 'app/utils/WebsocketActionsEnum';

const useStyles = makeStyles(() => ({
    lessPadding: {
        paddingLeft: '11px',
        paddingRight: '11px'
    },
    clocheWidth: {
        width: '56px'
    },
    titlePadding: {
        marginLeft: '8px'
    },
    utilsButtonsMaxWidth: {
        maxWidth: '96px'
    }
}));

function Header(props) {

    const history = useHistory();

    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const sizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const sizeUpSm = useMediaQuery('(min-width:550px)');

    const [displaySearch, setDisplaySearch] = useState(false);
    const [menuRightDrawer, setMenuRightDrawer] = useState(false);
    const [resized, setResized] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(0.0);
    const [maxWidth, setMaxWidth] = useState(0.0);
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [enteredCode, setEnteredCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [roomExists, setRoomExists] = useState(false);

    useEffect(() => {
        if(!props.isCandidateHeader) {
            dispatch(getCurrentUserById());
            dispatch(skillActions.getAllSkills());
        }
        if(props.isBigScreen) {
            dispatch(openDrawer());
        }

        return () => {
            setRoomExists(false);
        };
    }, []);

    useEffect(() => {
        if(!props.isBigScreen) {
            dispatch(closeDrawer());
        }
    }, [props.isBigScreen]);

    useEffect(() => {
        if(roomExists) {
            // eslint-disable-next-line no-use-before-define
            redirectToQuiz();
        }
    }, [roomExists]);

    useEffect(() => {
        setRoomExists(false);
        setErrorMessage('');
    }, [location]);

    const handleDisplaySearchChange = () => {
        setDisplaySearch(!displaySearch);
    };

    const handleMenuRightDrawerChange = () => {
        setMenuRightDrawer(!menuRightDrawer);
    };

    const toggleOpenLeftDrawer = () => {
        dispatch(setDisplayLeftDrawer(true));
    };

    const onHeaderResize = useCallback((width) => {
        if(width > currentWidth && resized) {
            setResized(!resized);
        } else if(!resized) {
            setCurrentWidth(width);
        }
    }, [currentWidth]);

    const onNavbarResize = useCallback((width, height) => {
        if(width < maxWidth || height > 64) {
            setResized(!resized);
        } else {
            setMaxWidth(width);
        }
    }, [maxWidth]);

    const { ref: header } = useResizeDetector({
        onResize: onHeaderResize
    });

    const { ref: navbar } = useResizeDetector({
        onResize: onNavbarResize,
        skipOnMount: true
    });

    const openDialog = () => {
        setIsOpenDialog(true);
    };

    const closeDialog = () => {
        setEnteredCode('');
        setIsOpenDialog(false);
    };

    const redirectToQuiz = () => {
        setErrorMessage('');
        closeDialog();
        history.push(`/quiz/${enteredCode}`);
    };

    const handleCodeChange = (event) => {
        setEnteredCode(event.target.value);
        setErrorMessage('');
    };

    const handleValidation = (event) => {
        event.preventDefault();
        getRoomExistsApi(enteredCode)
            .then(() => {
                if(roomExists) {
                    redirectToQuiz();
                }
            })
            .catch(() => {
                if(roomExists) {
                    setErrorMessage('Une erreur est survenue. Veuillez réessayer');
                } else {
                    setErrorMessage('Code incorrect. Veuillez réessayer');
                }
            });
    };

    return (
        <Fragment>
            {enteredCode && (
                <SockJsClient url={`${process.env.REACT_APP_BACK_END}websocket`}
                    topics={[`/quiz/${enteredCode}`]}
                    onMessage={(msg) => {
                        const { action, users, questionId } = msg;
                        if(action === WebSocketActionsEnum.EXISTS) {
                            dispatch(addRoom({
                                uuid: enteredCode,
                                currentQuestionId: Number(questionId),
                                users: users?.map((user) => {
                                    const { id, imageUrl, firstName, lastName } = user;
                                    return { id, imageUrl, firstName, lastName };
                                }),
                                isQuizStarted: questionId !== -1,
                                hostId: '-1'
                            }));
                            setRoomExists(true);
                        }
                    }}
                />
            )}
            <AppBar id="app-bar" position="sticky" ref={ header }>
                <Toolbar className={classes.lessPadding}>
                    <Grid container direction="column" justifyContent="center">
                        <Grid container direction="row" alignItems="center" justifyContent="space-between">
                            <Grid item xs={9} spacing={1} container direction="row" alignItems="center" justifyContent="flex-start">
                                <Grid item className={props.isBigScreen ? 'visible' : 'hidden'}>
                                    <Grid item>
                                        {props.params.includes(HeaderParams.DRAWER) &&
                                            <Tasks
                                                open={props.open}
                                                isCandidateHeader={props.isCandidateHeader}
                                            />}
                                    </Grid>
                                </Grid>
                                <Grid item className={props.isBigScreen ? '' : classes.titlePadding}>
                                    {props.params.includes(HeaderParams.TITLE) &&
                                        <Title
                                            isCandidateHeader={props.isCandidateHeader}
                                            isDrawerOpen={props.open}
                                        />
                                    }
                                </Grid>
                                <Grid item className={classes.clocheWidth}>
                                    {props.params.includes(HeaderParams.CLOCHE) && <ClocheNotification />}
                                </Grid>
                                <Grid item className={sizeUpMd && !resized ? 'hidden' : 'visible'}>
                                    <Grid item>
                                        {displaySearch && <SearchContainer />}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.utilsButtonsMaxWidth}
                                item xs={3} container direction="row" justifyContent="flex-end" alignItems="center" wrap="nowrap">
                                { sizeUpSm
                                    ? <Grid container direction="row" justifyContent="flex-end" alignItems="center" wrap="nowrap">
                                        <Grid item className={sizeUpMd && !resized ? 'hidden' : 'visible'}>
                                            {props.params.includes(HeaderParams.FILTER) &&
                                                    <IconButton color="inherit" onClick={toggleOpenLeftDrawer}>
                                                        <FilterList />
                                                    </IconButton>}
                                        </Grid>
                                        <Grid item className={sizeUpMd && !resized ? 'hidden' : 'visible'}>
                                            {props.params.includes(HeaderParams.SEARCH) &&
                                                    <IconButton color="inherit" onClick={handleDisplaySearchChange}>
                                                        <Search />
                                                    </IconButton>}
                                        </Grid>
                                    </Grid>
                                    : (props.params.includes(HeaderParams.FILTER) || props.params.includes(HeaderParams.SEARCH)) &&
                                        <>
                                            <FilterSearchMenu
                                                handleDisplaySearchChange={handleDisplaySearchChange}
                                                toggleOpenLeftDrawer={toggleOpenLeftDrawer}
                                            />
                                        </>
                                }
                                <Grid item className={sizeUpMd && !resized ? 'visible' : 'hidden'} ref={ navbar }>
                                    { props.params.includes(HeaderParams.RIGHT_NAV_BAR) && <NavbarLargeScreen /> }
                                </Grid>
                                <Grid item className={sizeUpMd && !resized ? 'visible' : 'hidden'}>
                                    { props.params.includes(HeaderParams.MENU) && <MenuLargeScreen /> }
                                </Grid>
                                { props.params.includes(HeaderParams.MENU) &&
                                    <Grid item className={sizeUpMd && !resized ? 'hidden' : 'visible'}>
                                        <IconButton className="header-menu-button" color="inherit"
                                            aria-label="Menu"
                                            onClick={handleMenuRightDrawerChange}>
                                            <MenuIcon />
                                        </IconButton>
                                        <MenuSmallScreen
                                            open={menuRightDrawer}
                                            onChange={handleMenuRightDrawerChange}
                                            onChangeIsOpenDialog={openDialog}
                                        />
                                        {isOpenDialog &&
                                            <DialogQuizInteractifMenuSmallScreen
                                                isOpenDialog={isOpenDialog}
                                                enteredCode={enteredCode}
                                                errorMessage={errorMessage}
                                                closeDialog={closeDialog}
                                                handleCodeChange={handleCodeChange}
                                                handleValidation={handleValidation}
                                            />
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Fragment>
    );
}

Header.propTypes = {
    params: PropTypes.arrayOf(PropTypes.oneOf(Object.values(HeaderParams))).isRequired,
    open: PropTypes.bool,
    isCandidateHeader: PropTypes.bool,
    isBigScreen: PropTypes.bool
};

export default Header;
