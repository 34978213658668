import { makeStyles } from '@material-ui/core';
import { AssignTypes } from 'app/utils/functions';

const canDropIn = (dragItem, dragDestination, newPos) => {
    switch (dragItem.type) {
        case AssignTypes.CHAPTER:
            const itemChapter = dragItem.chapter;
            const itemPath = `${itemChapter.parentPath}${itemChapter.name.toLowerCase()}/`;
            const oldPos = dragItem.chapter.position;
            return !dragDestination.key.startsWith(itemPath) && (dragItem.chapter.parentPath !== dragDestination.key || (oldPos !== newPos && oldPos + 1 !== newPos));
        case AssignTypes.QUESTION:
            return dragItem.chapterId !== dragDestination.content.id;
        default:
            return false;
    }
};

export const canDropInside = (dragItem, dragDestination) => {
    if(dragItem && dragDestination) {
        return canDropIn(dragItem, dragDestination, 1, 'IN');
    }
    return false;
};

export const canDropAfter = (dragItem, dragDestination) => {
    if(dragItem && dragDestination) {
        return canDropIn(dragItem, dragDestination.parent, dragDestination.content.position + 1, 'NEXT');
    }
    return false;
};

export const useStyles = makeStyles(() => ({
    iconContainer: {
        fontSize: '30px'
    },
    label: {
        fontSize: '1.2em',
        fontWeight: '500',
        lineHeight: '1.75',
        flexGrow: 1,
        width: 0
    },
    dropInChapterIcon: {
        color: 'green'
    }
}));

export const calculatePercentGoodAnswer = (stats) => {
    if(!stats || stats.questionsAnswered === 0) {
        return 0;
    }

    const totalQuestionsAnswered = stats.questionsAnswered;
    const openEndedQuestionsAnswered = stats.uniqueQuestionsOuvertesAnswered || 0;
    const mcqQuestionsAnswered = totalQuestionsAnswered - openEndedQuestionsAnswered;

    const percentGoodAnswerMCQ = mcqQuestionsAnswered > 0
        ? Math.round(stats.corrects / mcqQuestionsAnswered * 100)
        : 0;

    if(openEndedQuestionsAnswered > 0 && mcqQuestionsAnswered > 0) {
        const weightedMCQ = (mcqQuestionsAnswered / totalQuestionsAnswered) * percentGoodAnswerMCQ;
        const weightedOpenEnded = (openEndedQuestionsAnswered / totalQuestionsAnswered) * stats.averageGrade;
        return Math.round(weightedMCQ + weightedOpenEnded);
    } else if(openEndedQuestionsAnswered > 0) {
        return Math.round(stats.averageGrade);
    } else if(mcqQuestionsAnswered > 0) {
        return percentGoodAnswerMCQ;
    }
    return 0;

};
