
import React, { Component } from 'react';
import './ExcilyensContent.scss';
import { connect } from 'react-redux';
import { GET_ALL_EXCILYENS, getDetailedExcilyenSkills } from '../../redux/actions/Excilyen/ExcilyenSet/excilyenSet.actions';
import ExcilyenCard from '../../common/excilyenCard/ExcilyenCard';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import SidePanelExcilyens from '../../components/sidePanel/excilyen/SidePanelExcilyens';
import { selectDisplayLeftDrawer } from '../../redux/selectors/Display/displaySelector';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import { setDisplayLeftDrawer } from '../../redux/actions/Display/display.actions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ExcilyensLoader from './ExcilyensLoader';
import { createLoadingSelectorForStoreValue } from '../../redux/selectors/APISelector/APISelector';
import { selectExcilyensFilteredBySkillsAndPromosAndByName } from '../../redux/selectors/Excilyen/ExcilyenFilter/excilyensFilters.selector';
import {
    getAskedCoachesByStudentId,
    getCoachesByStudentId,
    GET_USER_ASKED_COACHES,
    GET_COACHES_BY_STUDENT_ID
} from '../../redux/actions/Coaching/coaching.actions';
import { selectUserAskedCoaches, selectUserCoaches } from '../../redux/selectors/Coaching/coaching.selector';
import AuthenticationContainer from '../../login/containers/AuthenticationContainer';
import { selectLoading } from '../../redux/selectors/Loading/loading.selector';
import { smallScreenBreakpoints } from 'app/utils/smallScreenBreakpoints';
import { withWidth } from '@material-ui/core';

export class ExcilyensContent extends Component {

    currentUserId = () => AuthenticationContainer.getCurrentUserId();

    toggleCloseLeftDrawer = () => {
        this.props.setDisplayLeftDrawer(false);
    };

    toggleOpenLeftDrawer = () => {
        this.props.setDisplayLeftDrawer(true);
    };

    componentDidMount() {
        this.props.getDetailedExcilyenSkills();
        this.props.getAskedCoachesByStudentId(this.currentUserId());
        this.props.getCoachesByStudentId(this.currentUserId());
    }

    render() {
        const excilyensCards = this.props.filteredExcilyensSet
            .filter(user => user.firstName !== null && user.lastName !== null)
            .sort((excilyen1, excilyen2) => excilyen1.firstName.localeCompare(excilyen2.firstName) ||
                excilyen1.lastName.localeCompare(excilyen2.lastName))
            .map((excilyen, index) =>
                <ExcilyenCard excilyen={excilyen} key={index}
                    isAlreadyAskedAsCoach={Boolean(this.props.askedCoachs) &&
                        this.props.askedCoachs.filter((askedCoach) => askedCoach.id === excilyen.id).length > 0}
                    isAlreadyCoach={Boolean(this.props.userCoachs) &&
                        this.props.userCoachs.filter((coach) => coach.id === excilyen.id).length > 0}
                />
            );

        let loader = null;
        switch (this.props.width) {
            case 'xl':
                loader = <ExcilyensLoader />;
                break;
            case 'lg':
                loader = <ExcilyensLoader />;
                break;
            case 'md':
                loader = <ExcilyensLoader />;
                break;
            case 'sm':
                loader = <ExcilyensLoader column={4} padding={30} />;
                break;
            case 'xs':
                loader = <ExcilyensLoader column={2} padding={30} />;
                break;
            default:
                loader = <ExcilyensLoader />;
        }

        return (
            <div className="experts-content" data-testid="experts-content">
                <Hidden only={smallScreenBreakpoints}>
                    <div className="expert-side-panel">
                        <SidePanelExcilyens />
                    </div>
                </Hidden>
                <div className="home-excilyen-cards" data-testid="home-excilyen-cards">
                    {   this.props.isFetchingExcilyens 
                        || this.props.isFetchingAskedCoachs 
                        || this.props.isFetchingCoachs
                        ? loader
                        : excilyensCards
                    }
                </div>
                <Hidden mdUp>
                    <SwipeableDrawer
                        open={this.props.displayLeftDrawer}
                        anchor="left"
                        onOpen={this.toggleOpenLeftDrawer}
                        onClose={this.toggleCloseLeftDrawer}
                    >
                        <div id="leftDrawer">
                            <IconButton onClick={this.toggleCloseLeftDrawer} className="drawer-icon">
                                <Icon>chevron_left</Icon>
                            </IconButton>
                            <SidePanelExcilyens />
                        </div>
                    </SwipeableDrawer>
                </Hidden>
            </div>
        );
    }
}

ExcilyensContent.propTypes = {
    filteredExcilyensSet: PropTypes.array,
    displayLeftDrawer: PropTypes.bool,
    isFetchingExcilyens: PropTypes.bool,
    isFetchingAskedCoachs: PropTypes.bool,
    isFetchingCoachs: PropTypes.bool,
    width: PropTypes.string,
    setDisplayLeftDrawer: PropTypes.func,
    getDetailedExcilyenSkills: PropTypes.func,
    userCoachs: PropTypes.array,
    askedCoachs: PropTypes.array,
    getCoachesByStudentId: PropTypes.func,
    getAskedCoachesByStudentId: PropTypes.func
};

function mapStateToProps(state) {
    return {
        filteredExcilyensSet: selectExcilyensFilteredBySkillsAndPromosAndByName(state),
        displayLeftDrawer: selectDisplayLeftDrawer(state),
        isFetchingExcilyens: createLoadingSelectorForStoreValue([GET_ALL_EXCILYENS], selectExcilyensFilteredBySkillsAndPromosAndByName)(state),
        isFetchingAskedCoachs: selectLoading(state)[GET_USER_ASKED_COACHES],
        isFetchingCoachs: selectLoading(state)[GET_COACHES_BY_STUDENT_ID],
        userCoachs: selectUserCoaches(state),
        askedCoachs: selectUserAskedCoaches(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        getDetailedExcilyenSkills: () => dispatch(getDetailedExcilyenSkills()),
        setDisplayLeftDrawer: (display) => dispatch(setDisplayLeftDrawer(display)),
        getCoachesByStudentId: (userId) => dispatch(getCoachesByStudentId(userId)),
        getAskedCoachesByStudentId: (userId) => dispatch(getAskedCoachesByStudentId(userId))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(withRouter(ExcilyensContent)));
