import axiosClient from './utils/axiosClient';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';

export const PARCOURS_URI = '/parcours';
const userId = AuthenticationContainer.getCurrentUserId();

export function getAllParcoursDisplayByCreatorIdApi() {
    return axiosClient.get(`${PARCOURS_URI}/created/${userId}`);
}

export function getAllFollowedParcoursDisplayByUserIdOrGroupIdApi(userIdFollowed, typeOfId) {
    return axiosClient.get(`${PARCOURS_URI}/followed/id/${userIdFollowed}/typeOfId/${typeOfId}`);
}

export function getEditionSharedParcoursDisplayByUserIdApi() {
    return axiosClient.get(`${PARCOURS_URI}/share/user/${userId}`);
}

export function deleteShareEditionWithUserApi(editor) {
    return axiosClient.delete(`${PARCOURS_URI}/share`, { data: editor });
}

export function shareEditionWithUsersApi(editors) {
    return axiosClient.post(`${PARCOURS_URI}/share`, editors);
}

export function getParcoursByIdApi(id) {
    return axiosClient.get(`${PARCOURS_URI}/${id}`);
}

export function getStepByParcoursIdApi(id) {
    return axiosClient.get(`${PARCOURS_URI}/${id}/steps`);
}

export function getStepContentByStepIdApi(id) {
    return axiosClient.get(`${PARCOURS_URI}/steps/${id}/contents`);
}

export function getNextParcoursHistoryByUserIdApi(parcoursId) {
    return axiosClient.get(`${PARCOURS_URI}/${parcoursId}/histories/users/${userId}/next`);
}

export function getParcoursHistoryByUserIdApi(parcoursId) {
    return axiosClient.get(`${PARCOURS_URI}/${parcoursId}/histories/users/${userId}`);
}

export function getRemediationByUserIdByQuestionIdApi(parcoursId, questionId) {
    return axiosClient.get(`${PARCOURS_URI}/${parcoursId}/remediation/users/${userId}/questions/${questionId}`);
}

export function getRemediationByUserIdAndMicroskillsIdsApi(parcoursId, microskillsIds) {
    const microskillsIdsString = microskillsIds.join(',');
    return axiosClient.get(`${PARCOURS_URI}/${parcoursId}/remediation/users/${userId}/microskills/${microskillsIdsString}`);
}

export function getParcoursStatusByUserId(user) {
    return axiosClient.get(`${PARCOURS_URI}/status/users/${user}`);
}

export function createParcoursApi(parcours) {
    return axiosClient.post(PARCOURS_URI, parcours);
}

export function updateParcoursApi(parcours) {
    return axiosClient.patch(PARCOURS_URI, parcours);
}

export function updateUserParcoursApi(parcoursId, userIdFollowed) {
    return axiosClient.patch(`${PARCOURS_URI}/${parcoursId}/users/${userIdFollowed}`);
}

export function updateGroupParcoursApi(parcoursId, groupIdFollowed) {
    return axiosClient.patch(`${PARCOURS_URI}/${parcoursId}/groups/${groupIdFollowed}`);
}

export function deleteParcoursApi(parcoursId) {
    return axiosClient.delete(`${PARCOURS_URI}/${parcoursId}`);
}

export function patchPublicVisibilityApi(parcoursId, publicVisibility) {
    return axiosClient.patch(`${PARCOURS_URI}/${parcoursId}/publicVisibility/${publicVisibility}`);
}
