import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import './InteractiveQuizRoom.scss';
import PropTypes from 'prop-types';
import SockJsClient from 'react-stomp';

import { Button, Grid, Tooltip } from '@material-ui/core';
import ResolveSerieRequestByChapterId from 'app/serie/ResolveSerie/ResolveSerieRequestByChapterId';
import { postInteractiveQuizStartedApi } from 'app/api/interactiveQuizApi';
import { QuestionResolver } from 'app/question/QuestionResolver/QuestionResolver';

import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { setCurrentQuestionId, setQuizStarted, updateRoom } from 'app/redux/actions/InteractiveQuiz/interactiveQuizRoom.actions';
import { selectIsQuizStartedRoomByUuid, selectSerieParamsRoomByUuid, selectShouldContinueQuizWithNewLeaderRoomByUuid } from 'app/redux/selectors/InteractiveQuiz/interactiveQuizRoom.selector';
import { selectSerieHistoryUpdate } from 'app/redux/selectors/Question/history.selector';
import { postSerieHistory } from 'app/redux/actions/Question/History/history.actions';

import { useDispatch, useSelector } from 'react-redux';
import { WebSocketActionsEnum } from 'app/utils/WebsocketActionsEnum';

function InteractiveQuizRoom({ uuid, users, currentQuestionId, hostId }) {
    const dispatch = useDispatch();
    const [currentQuestionValidated, setCurrentQuestionValidated] = useState(false);
    const isQuizStarted = useSelector(selectIsQuizStartedRoomByUuid(uuid));

    const [totalQuestionNb, setTotalQuestionNb] = useState(undefined);
    const serieHistoryUpdate = useSelector(selectSerieHistoryUpdate);

    const currentUserId = Number(AuthenticationContainer.getCurrentUserId());

    const isLeader = hostId === currentUserId;

    const handleStartSerie = () => {
        postInteractiveQuizStartedApi(uuid, currentQuestionId);
    };
    const serieParams = useSelector(selectSerieParamsRoomByUuid(uuid));
    const shouldContinueQuizWithNewLeader = useSelector(selectShouldContinueQuizWithNewLeaderRoomByUuid(uuid));

    // Contrôle des doublons d'utilisateurs qui se connectent (distinct)
    const filteredUsers = [...new Map(users.map((user) => [user.id, user])).values()];

    useEffect(() => {
        setCurrentQuestionValidated(false);
    }, [currentQuestionId]);

    return (
        <>
            <SockJsClient url={`${process.env.REACT_APP_BACK_END}websocket`}
                topics={[`/quiz/${uuid}`, `/quiz/${uuid}/userId/${currentUserId}`]}
                onMessage={(msg) => {
                    const { action, questionIds, skillId, chapterId } = msg;
                    switch (action) {
                        case WebSocketActionsEnum.QUIZ_STARTED:
                            dispatch(setQuizStarted({ uuid, questionId: Number(questionIds) }));
                            break;
                        case WebSocketActionsEnum.CURRENT_QUESTION_ID:
                            dispatch(setCurrentQuestionId({ uuid, questionId: Number(questionIds) }));
                            break;
                        case WebSocketActionsEnum.CURRENT_QUESTION_VALIDATED:
                            if(Number(questionIds) === currentQuestionId) {
                                setCurrentQuestionValidated(true);
                            }
                            break;
                        case WebSocketActionsEnum.SERIE_PARAMS:
                            const questionList = JSON.parse(questionIds);
                            const params = {
                                userId: AuthenticationContainer.getCurrentUserId(),
                                skillId: parseInt(skillId, 10),
                                chapterId: parseInt(chapterId, 10),
                                taskId: 0,
                                questionsId: questionList,
                                examMode: false
                            };
                            dispatch(updateRoom(uuid, {
                                serieParams: params
                            }));
                            dispatch(postSerieHistory(params));
                            setTotalQuestionNb(questionList.length);
                            break;
                        default:
                            break;
                    }
                } } />
            {isQuizStarted
                ? isLeader
                    ? (
                        <ResolveSerieRequestByChapterId
                            serieParams={serieParams}
                            shouldContinueQuizWithNewLeader={shouldContinueQuizWithNewLeader}
                            quizUuidFromLeader={uuid}
                            usersInRoom={users.filter((user) => user.id !== currentUserId)}
                        />
                    )
                    : currentQuestionId !== -1 && <QuestionResolver
                        id={currentQuestionId}
                        quizUuidFromPlayer={uuid}
                        usersInRoom={filteredUsers.slice(1)}
                        isCurrentInteractiveQuizQuestionValidated={currentQuestionValidated}
                        interactiveQuizSerieHistoryUpdate={serieHistoryUpdate}
                        interactiveQuizTotalNbQuestions={totalQuestionNb}/>

                : <div className="interactive-quiz-room">
                    <Paper className="interactive-quiz-room-paper">
                        <Grid container spacing={2} className="container">
                            <Grid container item spacing={2} direction="column" alignItems="center">
                                <h3 className="join-text">Pour rejoindre: </h3>
                                <h1 className="room-code-text">{uuid}</h1>
                                <h3 className="waiting-text">
                                    En attente de tous les participants
                                </h3>
                                <Grid container item justifyContent="center" style={{ columnGap: '8px' }}>
                                    {filteredUsers?.map((user) => (
                                        <Grid item key={user.id}>
                                            <Tooltip title={`${user.firstName} ${user.lastName}`}>
                                                <img
                                                    src={user.imageUrl}
                                                    alt={`User ${user.id}`}
                                                    className="avatar"
                                                    data-testid={`test-open-${user.id}`}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid container item className="interactive-quiz-room-paper-button" justifyContent="flex-end">
                                {(isLeader && filteredUsers?.length > 1) &&
                                    <Button
                                        onClick={handleStartSerie}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        LANCER LA SERIE
                                    </Button>}
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            }
        </>
    );
}

InteractiveQuizRoom.propTypes = {
    uuid: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
    currentQuestionId: PropTypes.number.isRequired,
    hostId: PropTypes.number.isRequired
};

export default InteractiveQuizRoom;
