import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import { StyledText } from 'app/utils/StyledText';
import { QuestionAskedEnum } from 'app/utils/QuestionEnum';
import ReactPlayer from 'react-player';

export function QuestionStatement(props) {
    const [showContextMenuAI, setShowContextMenuAI] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ xPos: 0, yPos: 0 });
    const [selectedTextToSend, setSelectedTextToSend] = useState();
    const containerRef = useRef(null);

    const isAiExplanationAvailable = () => !props.isExamMode &&
        !props.disableCourse &&
        !props.isTranslationMode &&
        props.questionType === QuestionAskedEnum.MultipleChoice;

    const handleContextHelpMenu = (event) => {
        const selectedText = window.getSelection().toString()
            .trim();

        if(selectedText) {
            setSelectedTextToSend(selectedText);

            event.preventDefault();

            const posX = event.pageX;
            const posY = event.pageY;

            setShowContextMenuAI(true);
            setContextMenuPosition({ xPos: posX, yPos: posY });
        }
    };

    const handleContextMenu = (event) => {
        if(isAiExplanationAvailable()) {
            handleContextHelpMenu(event);
        }
    };

    const handleRefMenu = () => isAiExplanationAvailable() ? containerRef : null;

    const handleMenuItemClick = () => {
        setShowContextMenuAI(false);
        props.onShowAiHelp(true, selectedTextToSend);
        props.setIsFetchingAiResponse(true);
    };

    const handleOutsideClick = (event) => {
        if(containerRef.current && !containerRef.current.contains(event.target)) {
            setShowContextMenuAI(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const parsedStatement = props.statement.replace(/(\S)<\/code>/ug, '$1 </code>');
    
    return (
        <>
            { isAiExplanationAvailable() &&
                <p style={{ color: 'gray', fontStyle: 'italic' }}>ⓘ Aide sur la question : Sélectionnez la partie du texte souhaitée et clique droit.</p>
            }
            <div
                ref={() => handleRefMenu()}
                onContextMenu={(event) => handleContextMenu(event)}>
                {props.videoStatement
                    ? (<>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ReactPlayer
                                url={props.videoStatement}
                                playIcon
                                playing />
                        </div>
                        <StyledText statement={parsedStatement} className={props.className} isQuestion />
                    </>)
                    : (<StyledText statement={parsedStatement} className={props.className } isQuestion />)
                }

                {showContextMenuAI && (
                    <div
                        style={{
                            position: 'fixed',
                            top: `${contextMenuPosition.yPos}px`,
                            left: `${contextMenuPosition.xPos}px`,
                            zIndex: 1000
                        }}
                    >
                        <Button
                            ref={(buttonRef) => (containerRef.current = buttonRef)}
                            variant="contained"
                            color="primary"
                            onClick={handleMenuItemClick}
                            disabled={props.isFetchingAiResponse}>
                            Expliquer via IA
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
}

QuestionStatement.propTypes = {
    statement: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    questionAsked: PropTypes.object.isRequired,
    onShowAiHelp: PropTypes.func,
    isExamMode: PropTypes.bool,
    setIsFetchingAiResponse: PropTypes.func,
    isFetchingAiResponse: PropTypes.bool,
    disableCourse: PropTypes.bool,
    questionType: PropTypes.string,
    isTranslationMode: PropTypes.bool,
    videoStatement: PropTypes.string
};

export default QuestionStatement;
