import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { SKILL_PAGE_URL } from 'app/Routes';
import ResolveSerieRequestByChapterId from './ResolveSerie/ResolveSerieRequestByChapterId';

import { Redirect, useLocation, withRouter } from 'react-router-dom';

function ChapterResolveRoute(props) {

    function useQuery() {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    const query = useQuery();

    const skillName = props.match.params.skillName;
    const skillId = Number(query.get('skillId'));
    const chapterId = Number(query.get('chapterId'));
    const nbQuestions = Number(query.get('nbQuestions'));
    const withChildren = query.get('withChildren') === 'true';
    const isRandomSerie = query.get('isRandomSerie') === 'true';
    const language = query.get('language');
    const maxDuration = query.get('maxDuration');

    const validateUrl = () => (
        skillId && skillId > 0 &&
        chapterId && chapterId > 0 &&
        nbQuestions && nbQuestions > 0
    );

    return (
        <>
            {validateUrl()
                ? <ResolveSerieRequestByChapterId
                    serieParams={{
                        skillName,
                        skillId,
                        chapterId,
                        nbQuestions,
                        withChildren,
                        isRandomSerie,
                        language
                    }}
                    maxDuration={maxDuration}
                />
                : <Redirect to={SKILL_PAGE_URL} />
            }
        </>
    );
}

ChapterResolveRoute.propTypes = {
    match: PropTypes.object.isRequired
};

export default withRouter(ChapterResolveRoute);
