
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Collapse, IconButton, ListSubheader, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import DrawerHistoryQuestionDisplay from './DrawerHistoryQuestionDisplay';
import { getQuestionsHistoryBySerieHistoryId } from 'app/redux/actions/Question/History/history.actions';
import { selectSerieHistory } from 'app/redux/selectors/Question/history.selector';

const useStyles = makeStyles(() => ({
    serie: {
        marginLeft: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 300,
        justifyContent: 'space-between'
    },
    truncate: {
        maxWidth: '80%'
    }
}));


function DrawerHistorySerieDisplay(props) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const serieHistory = useSelector(selectSerieHistory);

    const [openSerie, setOpenSerie] = useState(false);
    const [questionHistoryToDisplay, setQuestionHistoryToDisplay] = useState([]);

    useEffect(() => {
        if(serieHistory?.get(props.serieHistory.id)) {
            const questions = props.badAnswerFilter
                ? serieHistory?.get(props.serieHistory.id).filter((question) => question.result === 0)
                : serieHistory?.get(props.serieHistory.id);
            setQuestionHistoryToDisplay(questions);
        }
    }, [serieHistory, props.badAnswerFilter]);

    const handleOpenSerieHistory = () => {
        if(!serieHistory.get(props.serieHistory.id)) {
            dispatch(getQuestionsHistoryBySerieHistoryId(props.serieHistory.id));
        }
        setOpenSerie(!openSerie);
    };

    const getSerieHistoryText = () => `${props.serieHistory.title}`;
    const getSerieStats = () => `(${props.serieHistory.result}%)`;

    return (
        <>
            <ListSubheader className={classes.serie} >
                <Tooltip title={getSerieHistoryText()}>
                    <Typography noWrap className={classes.truncate} >
                        {getSerieHistoryText()}
                    </Typography>
                </Tooltip>
                <Typography className={classes.blankSpace} >
                    &nbsp;{getSerieStats()}
                </Typography>
                <IconButton
                    aria-label={`déplier la série ${props.serieHistory.id}`}
                    data-testid="show-question-histories-from-serie-history"
                    onClick={handleOpenSerieHistory}
                >
                    {openSerie ? <ExpandLess/> : <ExpandMore/>}
                </IconButton>
            </ListSubheader>
            <Collapse in={openSerie}>
                {questionHistoryToDisplay?.map((questionHistory) => (
                    <DrawerHistoryQuestionDisplay
                        key={`serie${serieHistory.id}:Question${questionHistory.id}`}
                        questionHistory={questionHistory}
                        onClickQuestion={props.onClickQuestion}
                        fromSerie
                    />
                ))}
            </Collapse>
        </>
    );
}

DrawerHistorySerieDisplay.propTypes = {
    badAnswerFilter: PropTypes.bool,
    serieHistory: PropTypes.object.isRequired,
    onClickQuestion: PropTypes.func.isRequired
};

export default DrawerHistorySerieDisplay;
