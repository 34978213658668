import { GET_CHAPTERS_STATS_SUCCESS, GET_USER_STATS_BY_CHAPTER_SUCCESS } from 'app/redux/actions/ChapterStats/chapterStats.actions';


export const userStats = 'userStats';

export default function chapterStatsReducer(state = {
    [userStats]: new Map()
}, action) {
    switch (action.type) {
        case GET_CHAPTERS_STATS_SUCCESS:
            const oldState = new Map(state[userStats].entries());
            Object.keys(action.payload).forEach((userId) => {
                const userIdInt = parseInt(userId, 10);
                if(!oldState.get(userIdInt)) {
                    oldState.set(userIdInt, new Map());
                }
                const chapterStats = oldState.get(userIdInt);
                Object.keys(action.payload[userId]).forEach((chapterId) => {
                    const chapterIdInt = parseInt(chapterId, 10);
                    if(!chapterStats.get(chapterIdInt)) {
                        chapterStats.set(chapterIdInt, new Map());
                    }
                    chapterStats.set(chapterIdInt, action.payload[userId][chapterId]);
                });
            });
            return {
                ...state,
                [userStats]: oldState
            };
        case GET_USER_STATS_BY_CHAPTER_SUCCESS:
            const oldStateStat = new Map(state[userStats].entries());
            action.payload.forEach((chapterStat) => {
                if(!oldStateStat.get(chapterStat.userId)) {
                    oldStateStat.set(chapterStat.userId, new Map());
                }
                oldStateStat.get(chapterStat.userId).set(
                    chapterStat.chapterId,
                    {
                        corrects: chapterStat.corrects,
                        questionsAnswered: chapterStat.questionsAnswered,
                        averageGrade: chapterStat.averageGrade,
                        uniqueQuestionsOuvertesAnswered: chapterStat.uniqueQuestionsOuvertesAnswered
                    }
                );
            });
            return {
                ...state,
                [userStats]: oldStateStat
            };
        default:
            return state;
    }
}
